import { Typography } from "@mui/material";
import "./MyProfile.css";
import { useEffect, useState } from "react";
import LoadingOverlay from "../../Shared/Components/Overlays/LoadingOverlay/LoadingOverlay";
import BasicTextField from "../../Shared/Components/TextFields/BasicTextField";
import { textFieldSizes, textFieldVariants } from "../../Shared/Components/TextFields/TextFieldEnums";
import BasicButton from "../../Shared/Components/Buttons/BasicButton";
import { buttonSizes, buttonVariants } from "../../Shared/Components/Buttons/ButtonEnums";
import { stringContainsNumbersOnly, stringIsNullOrEmpty, validateEmailAddress } from "../../Shared/Utils/string-utils";
import AlertDialog from "../../Shared/Components/Dialogs/AlertDialog/AlertDialog";
import ErrorDialog from "../../Shared/Components/Dialogs/ErrorDialog/ErrorDialog";
import UserService from "../../Shared/Services/user-service";
import UIProvider from "../../Shared/Components/Providers/UIProvider/UIProvider";
import userContextServiceInstance from "../../Shared/Services/user-context-service";
import { useMediaQuery } from "react-responsive";

const MyProfile = () => {
    const [showLoading, setShowLoading] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [idNumber, setIDNumber] = useState("");
    const [cellphone, setCellphone] = useState("");
    const [address, setAddress] = useState("");
    const [error, setError] = useState(false);
    const [missingInfoDialog, setMissingInfoDialog] = useState(false);
    const [profileUpdated, setProfileUpdated] = useState(false);
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const containerStyleClasses = isMobile ? "my-profile-container-small" : "my-profile-container-large";

    useEffect(() => {
        const getUserData = async () => {
            var localEmail = userContextServiceInstance.getEmail();
            var response = await UserService.GetDetails(localEmail);
            if(!response || response.status !== 200){
                setError(true);
                setShowLoading(false);
                return;
            }

            const userDetails = response.data;
            setFirstName(userDetails.name);
            setLastName(userDetails.surname);
            setEmail(userDetails.email);
            setIDNumber(userDetails.idNumber);
            setCellphone(userDetails.cellphone)
            setAddress(userDetails.address);
            setShowLoading(false);
        }

        setShowLoading(true);
        getUserData().catch(err => {
            console.error(err);
            setShowLoading(false);
            setError(true);
        });

    }, []);

    const validateFirstName = () => {
        return stringIsNullOrEmpty(firstName)
    }
    
    const validateLastName = () => {
        return stringIsNullOrEmpty(lastName);
    }

    const validateEmail = () => {
        return stringIsNullOrEmpty(email) || !validateEmailAddress(email);
    }

    const validateIDNumber = () => {
        return stringIsNullOrEmpty(idNumber) || idNumber.length !== 13 || !stringContainsNumbersOnly(idNumber);
    }

    const validateCellphone = () => {
        return stringIsNullOrEmpty(cellphone) || cellphone.length !== 10 || !stringContainsNumbersOnly(cellphone);
    }

    const validateAddress = () => {
        return stringIsNullOrEmpty(address);
    }

    const saveChangesClicked = async () => {
        if(validateFirstName() || validateLastName() || validateCellphone() || validateEmail() || 
            validateIDNumber() || validateAddress())
        {
            setMissingInfoDialog(true);
            return;
        }

        setShowLoading(true);

        const payload = {
            Name: firstName,
            Surname: lastName,
            IDNumber: idNumber,
            Email: email,
            Cellphone: cellphone,
            Address: address
        }
        const response = await UserService.UpdateUser(payload);
        if(!response || response.status !== 200 || response.data === false){
            setError(true);
            setShowLoading(false);
            return;
        }

        setProfileUpdated(true);
        setShowLoading(false);
    }

    return (
        <div className={containerStyleClasses}>
            <UIProvider>
                <Typography variant="h4" align="center" fontStyle="italic" fontWeight="bold">My Profile</Typography>
                <BasicTextField id="firstName" variant={textFieldVariants.outlined} label="First Name" size={textFieldSizes.small} value={firstName} 
                        onChange={(event) => { setFirstName(event.target.value) }} validateText={validateFirstName}/>
                <BasicTextField id="lastName" variant={textFieldVariants.outlined} label="Last Name" size={textFieldSizes.small} value={lastName} 
                    onChange={(event) => { setLastName(event.target.value) }} validateText={validateLastName}/>
                <BasicTextField id="email" variant={textFieldVariants.outlined} label="Email" size={textFieldSizes.small} value={email} 
                    onChange={(event) => { setEmail(event.target.value) }} validateText={validateEmail}/>
                <BasicTextField id="idNumber" variant={textFieldVariants.outlined} label="ID Number" size={textFieldSizes.small} value={idNumber} 
                    onChange={(event) => { setIDNumber(event.target.value) }} validateText={validateIDNumber}/>
                <BasicTextField id="cellphone" variant={textFieldVariants.outlined} label="Cellphone" size={textFieldSizes.small} value={cellphone} 
                    onChange={(event) => { setCellphone(event.target.value) }} validateText={validateCellphone}/>
                <BasicTextField id="address" variant={textFieldVariants.outlined} label="Address" size={textFieldSizes.small} value={address} 
                    onChange={(event) => { setAddress(event.target.value) }} validateText={validateAddress}/>
                <BasicButton variant={buttonVariants.contained} size={buttonSizes.small} text="Save changes" 
                    onClick={saveChangesClicked} color="primary"/>
            </UIProvider>
            <AlertDialog open={missingInfoDialog} handleClose={setMissingInfoDialog} title="Missing Information">
                Please ensure all fields are completed correctly.  
            </AlertDialog>
            <AlertDialog open={profileUpdated} handleClose={setProfileUpdated} title="Success">
                Your profile has been updated successfully.
            </AlertDialog>
            <ErrorDialog open={error} handleClose={setError} title="Error">
                An unexpected error occured. Please try again later.
            </ErrorDialog>
            {showLoading && <LoadingOverlay />}
        </div>
    );
}

export default MyProfile;