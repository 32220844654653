import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const CustomDatePicker = (props) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label={props.label} value={props.value} onChange={(newValue) => props.setValue(newValue)}
                format="YYYY-MM-DD"/>
        </LocalizationProvider>
    )
}

export default CustomDatePicker;