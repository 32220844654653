import BasicButton from "../../../Shared/Components/Buttons/BasicButton";
import { buttonSizes, buttonVariants } from "../../../Shared/Components/Buttons/ButtonEnums";
import "./SummaryView.css";

const SummaryView = (props) => {
    
    const refreshClicked = () => {
        props.refreshSummaryData();
    }

    return (
        <div className="summary-container">
            <div className="summary-items">
                <div className="summary-title">{props.title}</div>
                {props.children}
            </div>
            <div className="refresh-btn-container">
                <BasicButton variant={buttonVariants.contained} size={buttonSizes.small} text="Refresh" 
                    onClick={refreshClicked} color="primary"/>
            </div>
        </div>
    )
}

export default SummaryView;