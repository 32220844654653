import "./HeaderMenuItem.css";

const HeaderMenuItem = (props) => {
    return (
        <div className="header-menu-item-container" onClick={props.onClick}>
            {props.name}
        </div>
    );
}

export default HeaderMenuItem;