import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import WarningIcon from '@mui/icons-material/Warning';
import "./ErrorDialog.css";

const ErrorDialog = (props) => {

  const handleClose = () => {
    props.handleClose(false);
  };

  return (
    <Dialog
        open={props.open}
        onClose={handleClose}
      >
        <DialogTitle>
            <div className='error-dialog-title'>
                <div className='warning-icon'>
                    <WarningIcon color="warning" />
                </div>
                {props.title}
            </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="error-dialog-description">
            {props.children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
  );
}

export default ErrorDialog;