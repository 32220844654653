import { useState } from "react";
import BasicButton from "../../Shared/Components/Buttons/BasicButton";
import { buttonSizes, buttonVariants } from "../../Shared/Components/Buttons/ButtonEnums";
import BasicTextField from "../../Shared/Components/TextFields/BasicTextField";
import PasswordField from "../../Shared/Components/TextFields/PasswordField";
import { textFieldSizes, textFieldVariants } from "../../Shared/Components/TextFields/TextFieldEnums";
import "./SignUp.css";
import { Typography } from "@mui/material";
import { stringContainsNumbersOnly, stringIsNullOrEmpty, validateEmailAddress } from "../../Shared/Utils/string-utils";
import AlertDialog from "../../Shared/Components/Dialogs/AlertDialog/AlertDialog";
import LoadingOverlay from "../../Shared/Components/Overlays/LoadingOverlay/LoadingOverlay";
import UserService from "../../Shared/Services/user-service";
import ErrorDialog from "../../Shared/Components/Dialogs/ErrorDialog/ErrorDialog";
import { useNavigate } from 'react-router-dom';
import EmailOTP from "../EmailOTP/EmailOTP";
import Recaptcha from "../../Shared/Components/Recaptcha/Recaptcha";
import UIProvider from "../../Shared/Components/Providers/UIProvider/UIProvider";
import { useMediaQuery } from "react-responsive";

const SignUp = (props) => {
    let navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [idNumber, setIDNumber] = useState("");
    const [cellphone, setCellphone] = useState("");
    const [password1,setPassword1] = useState("");
    const [password2,setPassword2] = useState("");
    const [showOtpScreen, setShowOtpScreen] = useState(false);
    const [showUserExistsDialog, setShowUserExistsDialog] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [missingInfoDialog, setMissingInfoDialog] = useState(false);
    const [error, setError] = useState(false);
    const [userCreated, setUserCreated] = useState(false);
    const [address, setAddress] = useState("");
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const containerStyleClasses = isMobile ? "signup-container-small" : "signup-container-large";

    const signUpClicked = async () => {
        if(validateFirstName() || validateLastName() || validateCellphone() || validateEmail() || 
            validateIDNumber() || validatePassword() || validateAddress())
        {
            setMissingInfoDialog(true);
            return;
        }

        var createUserPayload = {
            FirstName: firstName,
            LastName: lastName,
            IDNumber: idNumber,
            Email: email,
            Cellphone: cellphone,
            Address: address,
            Password: password1
        }
        
        setShowLoading(true);
        UserService.createUser(createUserPayload).then(response => {
            if(!response){
                setError(true);
                setShowLoading(false);
                return;
            }

            if(response.status === 409){
                setShowUserExistsDialog(true);
                setShowLoading(false);
                return;
            }

            if(response.status !== 200){
                setError(true);
                setShowLoading(false);
                return;
            }
    
            setShowLoading(false);
            setShowOtpScreen(true);
        });
    }

    const validateFirstName = () => {
        return stringIsNullOrEmpty(firstName)
    }
    
    const validateLastName = () => {
        return stringIsNullOrEmpty(lastName);
    }

    const validateEmail = () => {
        return stringIsNullOrEmpty(email) || !validateEmailAddress(email);
    }

    const validateIDNumber = () => {
        return stringIsNullOrEmpty(idNumber) || idNumber.length !== 13 || !stringContainsNumbersOnly(idNumber);
    }

    const validateCellphone = () => {
        return stringIsNullOrEmpty(cellphone) || cellphone.length !== 10 || !stringContainsNumbersOnly(cellphone);
    }
    
    const validatePassword = () => {
        return password1 !== password2 || password1.length < 8;
    }

    const validateAddress = () => {
        return stringIsNullOrEmpty(address);
    }

    const submitOTPSuccess = async () => {
        resetSignUpForm();
        navigate("/login");
    }

    const resetSignUpForm = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setIDNumber("");
        setCellphone("");
        setPassword1("");
        setPassword2("");
    }

    const submitOtpFailure = () => {
        setShowOtpScreen(false);
        setError(true);
    }

    return (
        <div className={containerStyleClasses}>
            <UIProvider>
                {
                showOtpScreen ?
                    <EmailOTP email={email} submitOTPSuccess={submitOTPSuccess} submitOtpFailure={submitOtpFailure}/>
                :
                <>
                    <Typography variant="h4" align="center" fontStyle="italic" fontWeight="bold">Sign Up</Typography>
                    <BasicTextField id="firstName" variant={textFieldVariants.outlined} label="First Name" size={textFieldSizes.small} value={firstName} 
                        onChange={(event) => { setFirstName(event.target.value) }} validateText={validateFirstName}/>
                    <BasicTextField id="lastName" variant={textFieldVariants.outlined} label="Last Name" size={textFieldSizes.small} value={lastName} 
                        onChange={(event) => { setLastName(event.target.value) }} validateText={validateLastName}/>
                    <BasicTextField id="email" variant={textFieldVariants.outlined} label="Email" size={textFieldSizes.small} value={email} 
                        onChange={(event) => { setEmail(event.target.value) }} validateText={validateEmail}/>
                    <BasicTextField id="idNumber" variant={textFieldVariants.outlined} label="ID Number" size={textFieldSizes.small} value={idNumber} 
                        onChange={(event) => { setIDNumber(event.target.value) }} validateText={validateIDNumber}/>
                    <BasicTextField id="cellphone" variant={textFieldVariants.outlined} label="Cellphone" size={textFieldSizes.small} value={cellphone} 
                        onChange={(event) => { setCellphone(event.target.value) }} validateText={validateCellphone}/>
                    <BasicTextField id="address" variant={textFieldVariants.outlined} label="Address" size={textFieldSizes.small} value={address} 
                        onChange={(event) => { setAddress(event.target.value) }} validateText={validateAddress}/>
                    <PasswordField id="password1" variant={textFieldVariants.outlined} label="Password" size={textFieldSizes.small} value={password1} 
                        onChange={(event) => { setPassword1(event.target.value) }} validateText={validatePassword} fullWidth/>
                    <PasswordField id="password2" variant={textFieldVariants.outlined} label="Confirm password" size={textFieldSizes.small} value={password2} 
                        onChange={(event) => { setPassword2(event.target.value) }} validateText={validatePassword} fullWidth/>
                    <BasicButton variant={buttonVariants.contained} size={buttonSizes.small} text="Create account" 
                        onClick={signUpClicked} color="primary"/>
                </>   
                }
            </UIProvider>
            {showLoading && <LoadingOverlay />}
            <AlertDialog open={showUserExistsDialog} handleClose={setShowUserExistsDialog} title="User already registered!">
                User with email '{email}' is already registerd. Please sign in. 
            </AlertDialog>
            <AlertDialog open={missingInfoDialog} handleClose={setMissingInfoDialog} title="Missing Information">
                Please complete all required fields.  
            </AlertDialog>
            <AlertDialog open={userCreated} handleClose={setUserCreated} title="Success">
                Your user has been successfully created!
            </AlertDialog>
            <ErrorDialog open={error} handleClose={setError} title="Error">
                An unexpected error occured. Please try again later.
            </ErrorDialog>
        </div>
    );
}

export default SignUp;