import { CircularProgress, Typography } from "@mui/material";
import "./LoadingOverlay.css";

const LoadingOverlay = () => {

    return(
        <div className="loading-overlay">
            <div className="overlay-content">
                {/* <Typography variant="h3" color="black">Loading ...</Typography> */}
                <CircularProgress color="primary" size={50} />
            </div>
        </div>
    );
}

export default LoadingOverlay;