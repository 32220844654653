import { TextField } from "@mui/material";

//variant = outlined, filled or standard
const BasicTextField = (props) => {
    const required = props.required ? props.required : false;
    const disabled = props.disabled ? props.disabled : false;
    const showError = props.validateText ? props.validateText() : false;
    const fullWidth = props.fullWidth ? props.fullWidth : false;

    return (
        <TextField id={props.id} label={props.label} variant={props.variant} size={props.size} required={required} 
            disabled={disabled} error={showError} fullWidth={fullWidth} value={props.value} onChange={props.onChange}/>
    );
}

export default BasicTextField;