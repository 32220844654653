import { Typography } from "@mui/material";
import "./AppsGames.css";
import UIProvider from "../../Shared/Components/Providers/UIProvider/UIProvider";

const AppsGames = () => {
    return (
        <div className="apps-games-container">
            <UIProvider>
                <Typography variant="h4" align="center" fontStyle="italic" fontWeight="bold">Apps and Games</Typography>
            </UIProvider>
        </div>
    );
}

export default AppsGames;