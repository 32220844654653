import { useState } from "react";
import "./ResetPassword.css"
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "../../Shared/Components/Overlays/LoadingOverlay/LoadingOverlay";
import ErrorDialog from "../../Shared/Components/Dialogs/ErrorDialog/ErrorDialog";
import EmailOTP from "../EmailOTP/EmailOTP";
import { Typography } from "@mui/material";
import BasicTextField from "../../Shared/Components/TextFields/BasicTextField";
import BasicButton from "../../Shared/Components/Buttons/BasicButton";
import { buttonSizes, buttonVariants } from "../../Shared/Components/Buttons/ButtonEnums";
import { textFieldSizes, textFieldVariants } from "../../Shared/Components/TextFields/TextFieldEnums";
import UserService from "../../Shared/Services/user-service";
import PasswordField from "../../Shared/Components/TextFields/PasswordField";
import AlertDialog from "../../Shared/Components/Dialogs/AlertDialog/AlertDialog";
import UIProvider from "../../Shared/Components/Providers/UIProvider/UIProvider";
import { useMediaQuery } from "react-responsive";

const ResetPassword = () => {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [showLoading, setShowloading] = useState(false);
    const [error, setError] = useState(false);
    const [showOtpScreen, setShowOtpScreen] = useState(false);
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [showEnterEmail, setShowEnterEmail] = useState(true);
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [missingInfoDialog, setMissingInfoDialog] = useState(false);
    const [passwordHasReset, setPasswordHasReset] = useState(false);
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const containerStyleClasses = isMobile ? "reset-password-container-small" : "reset-password-container-large";

    const submitEmail = async () => {
        var response = await UserService.getDetailsForReset(email);     
        if(!response || response.status !== 200){
            setError(true);
            setShowEnterEmail(true);
            return;
        }
        
        setName(response.name + " " + response.surname);
        setShowEnterEmail(false);
        setShowOtpScreen(true);
    }

    const submitOTPSuccess = () => {
        setShowResetPassword(true);
        setShowOtpScreen(false);
        setShowEnterEmail(false);
    }

    const validatePassword = () => {
        return password1 !== password2 || password1.length < 8;
    }

    const submitNewPassword = async () => {
        if(password1 === "" || password2 === ""){
            setMissingInfoDialog(true);
            return;
        }

        if(password1 !== password2){
            return;
        }

        const payload = { Email: email, Password: password1 };
        var response = await UserService.resetPassword(payload);
        if(!response || response.status !== 200 || response.data !== true){
            setError(true);
            return;
        }

        setPasswordHasReset(true);
    }

    const submitOtpFailure = () => {
        setShowOtpScreen(false);
        setShowEnterEmail(true);
        setError(true);
    }

    const passwordResetSuccess = (value) => {
        setPasswordHasReset(false);
        navigate("/login");
    }

    return (
        <div className={containerStyleClasses}>
            <UIProvider>
                {showOtpScreen && <EmailOTP email={email} submitOTPSuccess={submitOTPSuccess} submitOtpFailure={submitOtpFailure}/>} 
                {showEnterEmail &&                  
                <>
                    <Typography variant="h4" align="center" fontStyle="italic" fontWeight="bold">Reset password</Typography>
                    <BasicTextField id="email" variant={textFieldVariants.standard} label="Email" size={textFieldSizes.medium} value={email} 
                        onChange={(event) => { setEmail(event.target.value) }}/>
                    <BasicButton variant={buttonVariants.contained} size={buttonSizes.medium} text="Submit" 
                        onClick={submitEmail} color="primary"/>
                </>
                }
                {showResetPassword &&
                <>
                    <Typography variant="h4" align="center" fontStyle="italic" fontWeight="bold">Reset password</Typography>
                    <PasswordField id="password1" variant={textFieldVariants.outlined} label="Password" size={textFieldSizes.small} value={password1} 
                        onChange={(event) => { setPassword1(event.target.value) }} validateText={validatePassword} fullWidth/>
                    <PasswordField id="password2" variant={textFieldVariants.outlined} label="Confirm password" size={textFieldSizes.small} value={password2} 
                        onChange={(event) => { setPassword2(event.target.value) }} validateText={validatePassword} fullWidth/>
                    <BasicButton variant={buttonVariants.contained} size={buttonSizes.medium} text="Submit" 
                        onClick={submitNewPassword} color="primary"/>
                </>
                }
            </UIProvider>
            {showLoading && <LoadingOverlay />}
            <ErrorDialog open={error} handleClose={setError} title="Error">
                An unexpected error occured. Please try again later.
            </ErrorDialog>
            <AlertDialog open={missingInfoDialog} handleClose={setMissingInfoDialog} title="Missing Information">
                Please complete all required fields.  
            </AlertDialog>
            <AlertDialog open={passwordHasReset} handleClose={passwordResetSuccess} title="Password Reset Success">
                Your password has successfully been reset.
            </AlertDialog>
        </div>
    );
}

export default ResetPassword;