import { HTTP_VERB } from "../../Enums/http-enums";
import HttpClient from "../HttpClient/http-client";
import { AxiosRequestConfig } from "axios";
import { API_HOST } from "../../api-config";

const baseUrl = API_HOST + "/api/business-manager/";

class OtherIncomeService {
    static instance: OtherIncomeService;
    client: typeof HttpClient;

    constructor(client: typeof HttpClient){
        if(!OtherIncomeService.instance){
            this.client = client;
            OtherIncomeService.instance = this;
            return OtherIncomeService.instance;
        }

        this.client = OtherIncomeService.instance.client;
        return OtherIncomeService.instance;
    }

    async addItem(data: Object){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.POST,
            baseUrl: baseUrl,
            url: baseUrl + "OtherIncome/AddItem",
            headers: {
                "Content-Type": "application/json"
            },
            data: data
        }

        return (await this.client.request(config));
    }

    async getItems(businessEntityId: String, year: number, month: number){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.GET,
            baseUrl: baseUrl,
            url: baseUrl + `OtherIncome/GetItems/${businessEntityId}/${year}/${month}`,
            headers: {
                "Content-Type": "application/json"
            },
            data: null
        }

        return (await this.client.request(config));
    }

    async GetItemsForPeriod(data: Object){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.POST,
            baseUrl: baseUrl,
            url: baseUrl + "OtherIncome/GetItemsForPeriod",
            headers: {
                "Content-Type": "application/json"
            },
            data: data
        }

        return (await this.client.request(config));
    }

    async updateItem(data: Object){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.PUT,
            baseUrl: baseUrl,
            url: baseUrl + "OtherIncome/UpdateItem",
            headers: {
                "Content-Type": "application/json"
            },
            data: data
        }

        return (await this.client.request(config));
    }

    async removeItems(data: Object){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.DELETE,
            baseUrl: baseUrl,
            url: baseUrl + "OtherIncome/RemoveItems",
            headers: {
                "Content-Type": "application/json"
            },
            data: data
        }

        return (await this.client.request(config));
    }
}

const otherIncomeServiceInstance = new OtherIncomeService(HttpClient);
Object.freeze(otherIncomeServiceInstance);

export default otherIncomeServiceInstance;