import { Typography } from "@mui/material";
import "./About.css";

const About = () => {

    return (
        <div className="about-container">
            <Typography variant="h4" align="center" fontStyle="italic" fontWeight="bold">About</Typography>
        </div>
    );
}

export default About;