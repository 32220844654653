export const stringContainsNumbersOnly = (value) => {
    return /^\d+$/.test(value);
}

export const stringIsNullOrEmpty = (value) => {
    return value === "" || value === null || value === undefined;
}

export const validateEmailAddress = (value) => {
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value);
}