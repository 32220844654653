import { IconButton, TextField } from "@mui/material";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useState } from "react";
import "./PasswordField.css";

const PasswordField = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const required = props.required ? props.required : false;
    const disabled = props.disabled ? props.disabled : false;
    const showError = props.validateText ? props.validateText() : false;
    const fullWidth = props.fullWidth ? props.fullWidth : false;

    return (
        <div className="password-container">
            <TextField id={props.id} label={props.label} variant={props.variant} size={props.size} required={required} 
                disabled={disabled} error={showError} fullWidth={fullWidth} value={props.value} onChange={props.onChange}
                type={showPassword ? "text" : "password"}/>
            <div className="icon-container">
                <IconButton onClick={() => { setShowPassword(!showPassword) }} >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
            </div>    
        </div>
    );
}

export default PasswordField;