import "./BusinessManagerDesktop.css";
import DesktopNavigationBar from "../DesktopNavigationBar/DesktopNavigationBar";
import React, { useState } from "react";
import Sales from "../Sales/Sales";
import { useNavigate } from "react-router-dom";

const BusinessManagerDesktop = (props) => {
    const [componentToLoad, setComponentToLoad] = useState(null);
    let navigate = useNavigate();

    const LoadableComponent = componentToLoad || <Sales/>; 
    
    return (
        <div className="business-manager-desktop-container">
            <DesktopNavigationBar setLoadableComponent={setComponentToLoad}/>
            <div className="desktop-component-content">
                {LoadableComponent}
            </div>
        </div>
    )
}

export default BusinessManagerDesktop;