import { useEffect, useState } from "react";
import "./Subscriptions.css";
import LoadingOverlay from "../../../Shared/Components/Overlays/LoadingOverlay/LoadingOverlay";
import ErrorDialog from "../../../Shared/Components/Dialogs/ErrorDialog/ErrorDialog";
import { PRODUCT_NAMES } from "../../../Shared/Enums/product-names";
import subscriptionServiceInstance from "../../../Shared/Services/subscription-service";
import SubscriptionCard from "./SubscriptionCard";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useMediaQuery } from "react-responsive";

const images = [
    "/Images/Help/Other_Income.png",
    "/Images/Help/sales.png",
    "/Images/Help/Expenses.png",
    "/Images/Help/Inventory.png",
    "/Images/Help/Assets.png",
    "/Images/Help/Overview.png",
];

const Subscriptions = (props) => {
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const [subscriptions, setSubscriptions] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [error, setError] = useState(false);
    const [noSubscriptionData, setNoSubscriptionData] = useState(false);
    const headingStyleClass = isMobile ? "text-header-small" : "text-header-large";
    const containerStyleClasses = isMobile ? "bm-subscription-container-small" : "bm-subscription-container-large";
    const carouselImageWidth = isMobile ? "100%" :"60%";

    useEffect(() => {
        const makeApiRequest = async () => {
            var response = await subscriptionServiceInstance.getSubscriptions(PRODUCT_NAMES.BusinessManager);
            if(response.status !== 200){
                setNoSubscriptionData(true);
                setShowLoading(false);
                return;
            }

            const activeSubscriptions = response.data;
            if(activeSubscriptions.length === 0){
                setNoSubscriptionData(true);
                setError(true);
                return;
            }

            const sortedItems = sortSubscriptions(activeSubscriptions);
            setSubscriptions(sortedItems);
            setShowLoading(false);
        }

        setShowLoading(true);
        makeApiRequest().catch(err => {
            console.error(err);
            setShowLoading(false);
            setNoSubscriptionData(true);
            //setError(true);
        });
    },[]);

    const sortSubscriptions = (items) => {
        for(let i = 0; i < items.length; i++){
            for(let k = i + 1; k < items.length; k++){
                if(items[k].monthlyDuration < items[i].monthlyDuration){
                    let tempItem = items[i];
                    items[i] = items[k];
                    items[k] = tempItem;
                }
            }
        }

        return items;
    }

    return (
        <div className={containerStyleClasses}>
            <div className="bm-subscription-content">
                <div className="subscription-section1">
                    <div className={headingStyleClass}>
                            Welcome to the Business Manager
                    </div>
                    <div className="header-subtext">
                        <p>
                            Let the Business Manager help you manage all your business admin. <br/><br/> 
                            Manage your business:
                            <ul>
                                <li>Sales</li>
                                <li>Expenses</li>
                                <li>Inventory costs</li>
                                <li>Other Income</li>
                                <li>Assets</li>
                            </ul>

                            Get an overview of your business performance. Instantly view all your records over a period of time.<br/><br/>
                            
                            View instant summaries of your business running costs over the year.<br/>
                            As long as you have an internet connection your can manage your business records from 
                            anywhere and at anytime. <br/><br/>
                            Generate an income statement for your business for any time period of your choice. <br/> 
                        </p>
                    </div>
                </div>

                <div className="subscription-section2">
                    <div className={headingStyleClass}>
                        Our Subscriptions
                    </div>
                    <div className="header-subtext">
                        <p>
                            You can view our <a href="#subscription-sections">subscriptions</a> here. <br/><br/>
                            Payments will be processed immediately. Once a subscription is purchased, it will only be valid
                            for the specified period of the subscription. <br/><br/> 
                            When the subscription period comes to an end the subscription will end and no automatic payments 
                            will be processed to renew the subscription. <br/><br/>
                            If you desire to renew your subscription, you can simply purchase a new subscription and continue 
                            using the Business Manager with all your data still saved. 
                        </p>
                    </div>
                </div>
                
                <div className="subscription-section3">
                    <div className={headingStyleClass}>
                        Sample Screenshots of Business Manager
                    </div>
                    <br/>
                    <div className="carousel-container">
                        <Carousel useKeyboardArrows={true} autoPlay infiniteLoop showThumbs={false} width={carouselImageWidth}>
                            {images.map((URL, index) => (
                            <div className="slide">
                                <img alt="sample_file" src={window.location.origin + URL} key={index}/>
                            </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </div>


            <div className="subscription-section4">
                <div id="subscription-sections" className="bm-subscription-options-container">
                    <div className={headingStyleClass}>
                        Choose your subscription!
                    </div>
                    <div className="bm-subscription-options-content">
                        {subscriptions.length > 0 && subscriptions.map(item => {
                            return <SubscriptionCard key={item.entityID} subscription={item} 
                                        setSupscriptionForPurhase={props.setSubscriptionForPurhase} />;
                        })}
                        {noSubscriptionData && <div>Unable to get subscription data. Please try again later.</div>}
                    </div>
                </div>
            </div>
            
            <ErrorDialog open={error} handleClose={setError} title="Error">
                An unexpected error occured. Please try again later.
            </ErrorDialog>
            {showLoading && <LoadingOverlay />}
        </div>
    );
}

export default Subscriptions;