import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { API_HOST } from "../../api-config";
import userContextServiceInstance from "../user-context-service";
import { HTTP_VERB } from "../../Enums/http-enums";


class HttpClient {
    static instance: HttpClient;
    client: AxiosInstance;

    constructor(){
        if(!HttpClient.instance){
            this.client = axios.create({
                baseURL: API_HOST + "/api/"
            });
        }

        HttpClient.instance = this;
        return HttpClient.instance;
    }

    async request(config: AxiosRequestConfig){

        try{
            let customConfig = this.addAdditionalHeaders(config);
            return await axios.request(customConfig);
        }
        catch(error){
            console.error(error);
            if (error.response?.status === 401){
                await this.refreshtoken(config.baseURL);

                try{
                    //retry to request
                    let customConfig = this.addAdditionalHeaders(config);
                    return await axios.request(customConfig);
                }
                catch(err){
                    window.location.href = "/";
                }
            }

            return error.response;
        }
    }

    addAdditionalHeaders(config){
        const userContext = userContextServiceInstance.getUserContext();

        if(userContext.AccessToken){
            //add custom headers here
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${userContext.AccessToken}`,
                ContextToken: userContext.ContextToken,
                Username: userContext.Email
            }
        }

        return config;
    }

    async refreshtoken(baseUrl, ){
        const userContext = userContextServiceInstance.getUserContext();

        try{
            const requestBody = {
                AccessToken: userContext.AccessToken,
                RefreshToken: userContext.RefreshToken,
                ContextToken: userContext.ContextToken
            }
    
            const refreshConfig: AxiosRequestConfig = {
                method: HTTP_VERB.POST,
                baseUrl: baseUrl,
                url: baseUrl + "Authorization/RefreshToken",
                headers: {
                    "Content-Type": "application/json"
                },
                data: requestBody
            }
    
            const response = await axios.request(refreshConfig);
            if(response.status !== 200){
                window.location.href = "/";
                return;
            }

            const responseData = response.data;
            userContextServiceInstance.updateAuthorizationTokens(responseData.accessToken, responseData.refreshtoken);
        }
        catch(error){
            console.error(error);
            window.location.href = "/";
        }
    }
}

const HttpClientInstance = new HttpClient();
Object.freeze(HttpClientInstance);

export default HttpClientInstance;