import { useEffect, useState } from "react";
import BasicButton from "../../Shared/Components/Buttons/BasicButton";
import { buttonSizes, buttonVariants } from "../../Shared/Components/Buttons/ButtonEnums";
import BasicTextField from "../../Shared/Components/TextFields/BasicTextField";
import { textFieldSizes, textFieldVariants } from "../../Shared/Components/TextFields/TextFieldEnums";
import { Typography } from "@mui/material";
import AlertDialog from "../../Shared/Components/Dialogs/AlertDialog/AlertDialog";
import LoadingOverlay from "../../Shared/Components/Overlays/LoadingOverlay/LoadingOverlay";
import UserService from "../../Shared/Services/user-service";
import ErrorDialog from "../../Shared/Components/Dialogs/ErrorDialog/ErrorDialog";
import "./EmailOTP.css";

const EmailOTP = (props) => {
    const [otp, setOtp] = useState("");
    const [incorrectOtp, setIncorrectOtp] = useState(false);
    const [otpCount, setOtpCount] = useState(0);
    const [showLoading, setShowLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const SendFirstOTP = async () => {
            await sendOTP(props.email);
    
            setOtpCount(otpCount + 1);
            setShowLoading(false);
        }

        setShowLoading(true);
        SendFirstOTP().catch(err => {
            console.error(err);
            setShowLoading(false);
            props.submitOtpFailure();
        });
    }, []);

    const sendOTP = async (email) => {
        var payload = { Email: email };
        var response = await UserService.sendOtpEmail(payload);
        if(!response.data){
            props.submitOtpFailure();
            return;
        }
    }

    const submitOTP = async () => {
        setShowLoading(true);
        const payload = { Email: props.email, Code: otp };
        UserService.verifyEmailOTP(payload).then(async (response) => {
            if(response.status !== 200){
                setError(true);
                setShowLoading(false);
                return;
            }
            
            if(!response.data){
                setIncorrectOtp(true);
                setShowLoading(false);
                return;
            }
            
            setShowLoading(false);
            props.submitOTPSuccess();
        })
        
    }

    const resendOTP = async () => {
        setOtpCount(otpCount + 1);
        await sendOTP(props.email);
    }

    return(
        <div className="otp-container">
            <Typography variant="h4" align="center">Email OTP</Typography>
            <Typography variant="h7" align="center">An email has been sent to you with an otp</Typography>
            <BasicTextField id="EmailOTP" variant={textFieldVariants.outlined} label="Email OTP" size={textFieldSizes.small} value={otp} 
                onChange={(event) => { setOtp(event.target.value) }} />
            <BasicButton variant={buttonVariants.contained} size={buttonSizes.small} text="Submit" 
                onClick={submitOTP} color="primary"/>
            <BasicButton variant={buttonVariants.outlined} size={buttonSizes.small} text="Resend OTP" 
                onClick={resendOTP} color="primary" disabled={otpCount === 4}/>
            <AlertDialog open={incorrectOtp} handleClose={setIncorrectOtp} title="Incorrect OTP">
                Incorrect OTP entered. Resend OTP or try again. 
            </AlertDialog>
            <ErrorDialog open={error} handleClose={setError} title="Error">
                An unexpected error occured. Please try again later.
            </ErrorDialog>
            {showLoading && <LoadingOverlay />}
        </div>
    )
}

export default EmailOTP;