import "./Subscriptions.css"
import { buttonSizes, buttonVariants } from "../../../Shared/Components/Buttons/ButtonEnums";
import BasicButton from "../../../Shared/Components/Buttons/BasicButton";
import userContextServiceInstance from "../../../Shared/Services/user-context-service";
import { useNavigate } from "react-router-dom";

const SubscriptionCard = (props) => {
    const navigate = useNavigate();
    const { subscription } = props;
    
    const purchaseClicked = () => {
        const contextToken = userContextServiceInstance.getContextToken();
        if(contextToken === null || contextToken === undefined){
            navigate("/login");
            return;
        }

        props.setSupscriptionForPurhase(subscription);
    }

    return (
        <div className="bm-subcard-container">
            <div className="bm-subcard-content">
                <div>{subscription.description}</div>
                <div>R{subscription.price}</div>
                <BasicButton variant={buttonVariants.contained} size={buttonSizes.medium} text="purchase" 
                        onClick={purchaseClicked} color="primary"/>
            </div>
        </div>
    );
}

export default SubscriptionCard;