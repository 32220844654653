import { HTTP_VERB } from "../../Enums/http-enums";
import HttpClient from "../HttpClient/http-client";
import { AxiosRequestConfig } from "axios";
import { API_HOST } from "../../api-config";

const baseUrl = API_HOST + "/api/business-manager/";

class StockTypeService {
    static instance: StockTypeService;
    client: typeof HttpClient;

    constructor(client: typeof HttpClient){
        if(!StockTypeService.instance){
            this.client = client;
            StockTypeService.instance = this;
            return StockTypeService.instance;
        }

        this.client = StockTypeService.instance.client;
        return StockTypeService.instance;
    }

    async addItem(data: Object){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.POST,
            baseUrl: baseUrl,
            url: baseUrl + "StockType/AddItem",
            headers: {
                "Content-Type": "application/json"
            },
            data: data
        }

        return (await this.client.request(config));
    }

    async getItems(businessEntityId: string){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.GET,
            baseUrl: baseUrl,
            url: baseUrl + "StockType/GetItems/" + businessEntityId,
            headers: {
                "Content-Type": "application/json"
            },
            data: null
        }

        return (await this.client.request(config));
    }

    async updateItem(data: Object){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.PUT,
            baseUrl: baseUrl,
            url: baseUrl + "StockType/UpdateItem",
            headers: {
                "Content-Type": "application/json"
            },
            data: data
        }

        return (await this.client.request(config));
    }

    async removeItems(data: Object){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.DELETE,
            baseUrl: baseUrl,
            url: baseUrl + "StockType/RemoveItems",
            headers: {
                "Content-Type": "application/json"
            },
            data: data
        }

        return (await this.client.request(config));
    }
}

const stockTypeServiceInstance = new StockTypeService(HttpClient);
Object.freeze(stockTypeServiceInstance);

export default stockTypeServiceInstance;