import "./Assets.css";
import DataGrid from "../DataGrid/DataGrid";
import LoadingOverlay from "../../../Shared/Components/Overlays/LoadingOverlay/LoadingOverlay";
import { useEffect, useState } from "react";
import ErrorDialog from "../../../Shared/Components/Dialogs/ErrorDialog/ErrorDialog";
import { validDecimalNumber } from "../../../Shared/Utils/number-utils";
import { v4 as uuidv4 } from 'uuid';
import userContextServiceInstance from "../../../Shared/Services/user-context-service";
import assetServiceInstance from "../../../Shared/Services/BusinessManager/asset-service";
import { convertDateObjectToString, convertDateStringToDateObject, getMonthDiff, validateDateString } from "../../../Shared/Utils/date-utils";
import { calculateCompoundInterestValue, calculateDepreciatedValue, calculateSimpleInterestValue } from "../../../Shared/Utils/formula-utils";

const Assets = (props) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [rows, setRows] = useState([]);
    const [headers, setHeaders] = useState([]);
    
    useEffect(() => {
        const getIncomeData = async () => {

            const gridHeaders = [
                {id: 1, value: "Description", displayValue: "Asset Description", cellWidth: 250, validCellValue: validDescription, isEditable: true}, 
                {id: 2, value: "StartingAmount", displayValue: "Principle Value (R)", cellWidth: 150, validCellValue: validAmount, isEditable: true},
                {id: 3, value: "StartDate", displayValue: "Date of purchase", cellWidth: 150, validCellValue: validDate, isEditable: true},
                {id: 4, value: "InterestType", displayValue: "Interest type", cellWidth: 150, validCellValue: validInterestType, isEditable: true, isDropdownCell: true},
                {id: 5, value: "CompoundPeriod", displayValue: "Compounding Period", cellWidth: 200, validCellValue: validCompoundPeriods, isEditable: true, isDropdownCell: true},                
                {id: 6, value: "InterestRate", displayValue: "Interest rate p.a.(%)", cellWidth: 150, validCellValue: validInterestRate, isEditable: true},
                {id: 7, value: "FutureValue", displayValue: "Current Value (R)", cellWidth: 150, validCellValue: null, isEditable: false},
            ];
    
            setHeaders(gridHeaders);
            
            await getGridRowDataFromApi();

            setLoading(false);
        }
        
        getIncomeData().catch(err => {
            console.error(err);
            setLoading(false);
            setError(true);
        })

    }, []);

    const getDropdownCellOptions = (headerValue, row) => {
        var options = [];

        switch(headerValue){
            case "InterestType":
                return [
                    {id: "IT0", value: "Simple", text: "Simple"},
                    {id: "IT2", value: "Compound", text: "Compound"},
                    {id: "IT3", value: "Depreciation", text: "Depreciation"},
                ];
            
            case "CompoundPeriod":
                return [
                    {id: "CP0", value: "365", text: "Daily (365)"},
                    {id: "CP2", value: "360", text: "Daily (360)"},
                    {id: "CP3", value: "52", text: "Weekly"},
                    {id: "CP4", value: "26", text: "Biweekly"},
                    {id: "CP5", value: "24", text: "Semimonthly"},
                    {id: "CP6", value: "12", text: "Monthly"},
                    {id: "CP7", value: "4", text: "Quarterly"},
                    {id: "CP8", value: "2", text: "Semiannually"},
                    {id: "CP9", value: "1", text: "Annualy"},
                    {id: "CP10", value: "N/A", text: "N/A", hidden: true},
                ];

            default:
                return options;
        }
    }

    const getGridRowDataFromApi = async () => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        var response = await assetServiceInstance.getItems(businessEntityId);
        if(response.status !== 200){
            setError(true);
            setLoading(false);
            return;
        }

        let items = response.data;

        let gridRows = [];
        items.forEach(item => {
            let date = convertDateObjectToString(item.startDate);
            gridRows.push(
                {
                    id: item.entityID,
                    rowData: { Description: item.description, StartingAmount: item.startingAmount, StartDate: date, InterestType: item.interestType, CompoundPeriod: item.compoundPeriod, InterestRate: item.interestRate }, 
                    hasError: false,
                    rowSelected: false,
                    isEmpty: false,
                    hasChanges: false,
                    isComplete: true,
                    isNewRow: false
                }
            );
        });

        gridRows = sortGridRows(gridRows);
        setRows(gridRows);
    }

    const sortGridRows = (rows) => {
        rows.sort((a, b) => {
            let description1 = a.rowData["Description"];
            let description2 = b.rowData["Description"];
            if(description1 === "" || description2 === "") { return 1; }
            return description1.localeCompare(description2);
        });

        return rows;
    }

    const getGridRowCellValue = (headerValue, row) => {
        switch(headerValue){
            case "FutureValue":
                return calculateFutureAssetValue(row);
            default:
                return row.rowData[headerValue];
        }
    }

    const calculateFutureAssetValue = (row) => {
        if(row.rowData["InterestType"] === "" || !validAmount(row.rowData["StartingAmount"]) || 
            !validDate(row.rowData["StartDate"]) || !validInterestRate(row.rowData["InterestRate"]) 
            || row.rowData["InterestRate"] === ""){
            return "";
        }

        var interestType = row.rowData["InterestType"];
        var purchaseValue = parseFloat(row.rowData["StartingAmount"]);
        var startDate = convertDateStringToDateObject(row.rowData["StartDate"]);
        var interestRate = parseFloat(row.rowData["InterestRate"]);
        var currentDate = new Date();
        if(currentDate < startDate){
            return purchaseValue;
        }
        var numberMonths = getMonthDiff(startDate, currentDate);
        
        if(interestType === "Depreciation"){
            return calculateDepreciatedValue(purchaseValue, interestRate, numberMonths);
        }
        else if(interestType === "Simple"){
            return calculateSimpleInterestValue(purchaseValue, interestRate, numberMonths);
        }
        else if(interestType === "Compound"){
            var compoundPeriod = parseFloat(row.rowData["CompoundPeriod"]);
            return calculateCompoundInterestValue(purchaseValue, interestRate, numberMonths, compoundPeriod);
        }

        return "";
    }

    const assignRowValues = (row, header, newValue) => {
        row.rowData[header.value] = newValue;
        if(header.value === "InterestType"){
            if(newValue === "Simple" || newValue === "Depreciation"){
                row.rowData["CompoundPeriod"] = "N/A";
            }
            else if(newValue === "Compound"){
                row.rowData["CompoundPeriod"] = "";
            }
        }

        if(header.value === "CompoundPeriod"){
            if(row.rowData["InterestType"] === "Simple" || row.rowData["InterestType"] === "Depreciation"){
                row.rowData[header.value] = "N/A";
            }
        }

        return row;
    }

    const getEmptyRow = () => {
        return { 
            id: uuidv4(), 
            rowData: {Description: "", StartingAmount: "", StartDate: "", InterestType: "", CompoundPeriod: "", InterestRate: ""}, 
            hasError: false, 
            rowSelected: false, 
            isEmpty: true,
            hasChanges: false,
            isComplete: true,
            isNewRow: true
        };
    }

    const canCommitRow = (row) => {
        return row.rowData["Description"] !== "" && row.rowData["StartingAmount"] !== "" && row.rowData["StartDate"] !== "" 
            && row.rowData["InterestType"] !== "" && row.rowData["InterestRate"] !== "" && row.rowData["CompoundPeriod"] !== "";
    }

    const validDescription = (value) => {
        return value !== null && value !== undefined && value.trim() !== "";
    }

    const validAmount = (value) => {
        if(value === ""){
            return true;
        }
        
        return validDecimalNumber(value);
    }

    const validInterestRate = (value) => {
        if(value === ""){
            return true;
        }
        
        return validDecimalNumber(value);
    }

    const validDate = (value) => {
        if(value === ""){
            return true;
        }

        return validateDateString(value);
    }

    const validInterestType = (value) => {
        return value !== "" && value !== "blank" && value !== null && value !== undefined;
    }

    const validCompoundPeriods = (value) => {
        return value !== "" && value !== "blank" && value !== null && value !== undefined;
    }

    const saveAssetChanges = (row) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        let payload = {
            EntityID: row.id,
            BusinessEntityID: businessEntityId,
            Description: row.rowData["Description"],
            StartingAmount: parseFloat(row.rowData["StartingAmount"]),
            StartDate: convertDateStringToDateObject(row.rowData["StartDate"]),
            InterestType: row.rowData["InterestType"],
            InterestRate: parseFloat(row.rowData["InterestRate"]),
            CompoundPeriod: row.rowData["CompoundPeriod"]
        };

        setLoading(true);

        if(row.isNewRow){
            assetServiceInstance.addItem(payload).then((response) => {
                if(response.status !== 200){
                    setError(true);
                    setLoading(false);
                    return;
                }
    
                setLoading(false);
            });
        }
        else {
            assetServiceInstance.updateItem(payload).then((response) => {
                if(response.status !== 200){
                    setError(true);
                    setLoading(false);
                    return;
                }
    
                setLoading(false);
            });
        }
    }

    const deleteRows = async (guidList) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        let payload = {
            BusinessEntityID: businessEntityId,
            AssetEntityIDs: guidList
        };

        setLoading(true);
        var response = await assetServiceInstance.removeItems(payload);
        if(response.status !== 200){
            setError(true);
            setLoading(false);
            return;
        }

        setLoading(false);
        await getGridRowDataFromApi();
    }

    return (
        <div className="assets-container">
            <div className="assets-grid-container">
                <DataGrid gridRows={rows} gridHeaders={headers} saveGridChanges={saveAssetChanges} getEmptyRow={getEmptyRow}
                    getCellValue={getGridRowCellValue} canCommitRow={canCommitRow} deleteRows={deleteRows} sortGridRows={sortGridRows}
                    assignRowValues={assignRowValues} getDropdownCellOptions={getDropdownCellOptions}/>
            </div>
            
            <ErrorDialog open={error} handleClose={setError} title="Error">
                An unexpected error occured. Please try again later.
            </ErrorDialog>
            {loading && <LoadingOverlay />}
        </div>
    )
}

export default Assets;