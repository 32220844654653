import "./SummaryView.css";

const SummaryItem = (props) => {
    return (
        <div className="summary-item">
            <div className="summary-item-text">{props.label}</div>
            <div className="summary-item-value">{props.value}</div>
        </div>
    );
}

export default SummaryItem;