import "./BusinessManagerMobile.css"

const BusinessManagerMobile = (props) => {
    return (
        <div className="business-manager-mobile-container">
            <div className="business-manager-mobile-content">
                Mobile version coming soon!
            </div>
        </div>
    )
}

export default BusinessManagerMobile;