import { HTTP_VERB } from "../../Enums/http-enums";
import HttpClient from "../HttpClient/http-client";
import { AxiosRequestConfig } from "axios";
import { API_HOST } from "../../api-config";

const baseUrl = API_HOST + "/api/business-manager/";

class CategoryItesService {
    static instance: CategoryItesService;
    client: typeof HttpClient;

    constructor(client: typeof HttpClient){
        if(!CategoryItesService.instance){
            this.client = client;
            CategoryItesService.instance = this;
            return CategoryItesService.instance;
        }

        this.client = CategoryItesService.instance.client;
        return CategoryItesService.instance;
    }

    async addItem(data: Object){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.POST,
            baseUrl: baseUrl,
            url: baseUrl + "CategoryItems/AddItem",
            headers: {
                "Content-Type": "application/json"
            },
            data: data
        }

        return (await this.client.request(config));
    }

    async updateItem(data: Object){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.PUT,
            baseUrl: baseUrl,
            url: baseUrl + "CategoryItems/UpdateItem",
            headers: {
                "Content-Type": "application/json"
            },
            data: data
        }

        return (await this.client.request(config));
    }

    async removeItems(data: Object){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.DELETE,
            baseUrl: baseUrl,
            url: baseUrl + "CategoryItems/RemoveItems",
            headers: {
                "Content-Type": "application/json"
            },
            data: data
        }

        return (await this.client.request(config));
    }
}

const categoryItesServiceInstance = new CategoryItesService(HttpClient);
Object.freeze(categoryItesServiceInstance);

export default categoryItesServiceInstance;