import { getNumberOfEmptyRows, rowIsEmpty } from "../../../Shared/Utils/grid-utils";
import "./DataGrid.css";
import { useEffect, useState } from "react";
import ConfirmationDialog from "../../../Shared/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { Button } from "@mui/material";
import LoadingOverlay from "../../../Shared/Components/Overlays/LoadingOverlay/LoadingOverlay";

const DataGrid = (props) => {
    const { gridRows, gridHeaders } = props;
    const [useRowNumbers, setUseRowNumbers] = useState(true);
    const [rowWidth, setRowWidth] = useState(0);
    const [rows, setRows] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [defaultExtraRows, setDefaultExtraRows] = useState(5);
    const [showDeleteRowsDialog, setShowDeleteRowsDialog] = useState(false);
    const [initialEmptyGridRowCount, setInitialEmptyGridRowCount] = useState(10);
    const [loading, setLoading] = useState(true);
    const [gridErrors, setGridErrors] = useState([]);       
    const [showGridOperations, setShowGridOperations] = useState(true);
    const cellHeight = 26;

    // this is the row model:
    // { id: k, rowData: {}, hasError: false, rowSelected: false, isEmpty: false, hasChanges: false, isComplete: true, isNewRow: false }


    // this is a header model:
    //{id: 1, value: "Description", displayValue: "Description", cellWidth: 250, validCellValue: validDescription, isEditable: false, isDropdownCell: false, dropdownOptions: getStockTypeOptions}
    
    useEffect(() => {
        const totalWidth = calculateRowWidth(gridHeaders);
        
        addEmptyRows(gridRows);
        setHeaders(gridHeaders);
        setRowWidth(totalWidth);
        if(props.useRowNumbers !== null && props.useRowNumbers !== undefined){
            setUseRowNumbers(props.useRowNumbers);
        }

        if(props.useGridOperations !== null && props.useGridOperations !== undefined){
            setShowGridOperations(props.useGridOperations);
        }

        setLoading(false);

    }, [gridRows, gridHeaders]);

    const addEmptyRows = (currentRows) => {
        let extraRowsToAdd = currentRows.length === 0 ? initialEmptyGridRowCount : defaultExtraRows;
        let emptyRowCount = getNumberOfEmptyRows(currentRows);

        if(props.getEmptyRow){
            for(let i = 1; i <= extraRowsToAdd - emptyRowCount; i++){
                currentRows.push(props.getEmptyRow());
            }
        }

        setRows(currentRows);
        setRowCount(currentRows.length);
    }

    const calculateRowWidth = (headers) => {
        let totalWidth = 0;
        
        headers.forEach(columnHeader => {
            totalWidth += columnHeader.cellWidth;
        });

        return totalWidth;
    }

    const updateCellValue = (rowUpdated, header, value) => {
        let clonedRows = Object.assign([], rows);
        
        clonedRows.forEach(row => {
            if(row.id === rowUpdated.id){
                header.validCellValue(value) ? row.hasError = false : row.hasError = true;
                row = props.assignRowValues(row, header, value);
                row.isEmpty = false;
                row.hasChanges = true;
                props.canCommitRow(row) ? row.isComplete = true : row.isComplete = false;

                //handle an empty row
                if(rowIsEmpty(row, headers)){
                    row.isComplete = true;  
                    row.hasError = false;
                }

                // handle grid errors
                if(row.hasError && !gridErrors.includes(row.id)){
                    gridErrors.push(row.id);
                    setGridErrors(gridErrors);
                }
                else if(!row.hasError && gridErrors.includes(row.id)){
                    const index = gridErrors.findIndex((value) => { return value === row.id });
                    if(index !== undefined){
                        gridErrors.splice(index, 1);
                        setGridErrors(gridErrors);
                    }
                }
            }
        });

        addEmptyRows(clonedRows);
    }

    const rowNumberClicked = (rowClicked) => {
        let clonedRows = Object.assign([], rows);
        
        clonedRows.forEach(row => {
            if(row.id === rowClicked.id){
                row.rowSelected = !row.rowSelected;
                setRows(clonedRows);
                if(props.rowNumberClickedHandler){
                    props.rowNumberClickedHandler(row);
                }
                return;
            }
        });
    }

    const cellClicked = (rowClicked) => {
        let clonedRows = Object.assign([], rows);
        
        clonedRows.forEach(row => {
            row.rowSelected = false;
        });

        if(props.cellClickedHandler){
            props.cellClickedHandler(rowClicked);
        }

        setRows(clonedRows);
    }

    const getStyleClasses = (row, header) => {
        // dropdown cells
        if(header.isDropdownCell){
            let styleClasses =  "select-input";
            if(row.hasError){
                styleClasses += " select-input-error";
            }

            if(!row.hasError && !row.isComplete){
                styleClasses += " select-input-not-complete";
            }

            if(row.rowSelected){
                styleClasses += " select-input-row-selected";
            }

            return styleClasses;
        }

        // normal cells
        let styleClasses =  "cell-input";
        if(row.hasError){
            styleClasses += " cell-error";
        }

        if(!row.hasError && !row.isComplete){
            styleClasses += " cell-not-complete";
        }

        if(row.rowSelected){
            styleClasses += " row-selected";
        }

        return styleClasses;
    }

    const deleteSelectedRows = async () => {
        setShowDeleteRowsDialog(false);
        let ids = [];

        for (var i = rows.length -1; i >= 0; i--){
            if(rows[i].rowSelected){
                ids.push(rows[i].id);
            }
        }

        await props.deleteRows(ids)
    }

    const addRowsTogridClicked = () => {
        if(props.getEmptyRow){
            rows.push(props.getEmptyRow());
            setRows(rows);
            setRowCount(rows.length);
        }
    }

    const saveCellChanges = async (row) => {
        let canCommit = false;
        if(!row.hasError && row.hasChanges){
            canCommit = props.canCommitRow(row);
            if(canCommit){
                await props.saveGridChanges(row);
            }
        }

        if(canCommit){
            let clonedRows = Object.assign([], rows);
            clonedRows.forEach(clonedRow => {
                if(clonedRow.id === row.id)
                {
                    clonedRow.hasChanges = false;
                    clonedRow.isNewRow = false;
                }
            });
    
            clonedRows = props.sortGridRows(clonedRows);
            setRows(clonedRows);
        }
    }

    const getDropdownOptions = (headerValue, row) => {
        let options = [<option key="0" value="blank"></option>];
        
        var cellOptions = props.getDropdownCellOptions(headerValue, row);
        cellOptions.forEach(item => {
            options.push(
                <option key={item.id} value={item.value} hidden={item.hidden ? item.hidden : false}>{item.text}</option>
            );
        });

        return options;
    }

    return (
        <div className="data-grid-container">
            <div className="data-grid-header">
                <div className="filters">
                    {props.getGridFilters && props.getGridFilters()}
                </div>
                {showGridOperations && <div className="data-grid-operations">
                    <Button variant="contained" onClick={addRowsTogridClicked} color="success">Add row</Button>
                    <Button variant="contained" onClick={() => {setShowDeleteRowsDialog(true)}} color="error">Delete row</Button>
                </div>}
            </div>
            <div className="data-grid">
                <div className="data-grid-row-headers">
                    <div className="row" style={{width: rowWidth}}>
                        {useRowNumbers && <div className="row-number-container"><input className="column-header" value="" disabled/></div>}
                        {headers.map(header => {
                            return (
                                <div key={"headerdiv"+header.id} style={{width: header.cellWidth, height: cellHeight, border: "solid 1px rgb(131, 130, 130)"}}>
                                    <input key={header.id} className="column-header" value={header.displayValue} disabled />
                                </div>
                            )
                        })}
                    </div>
                </div>
                
                <div className="data-grid-rows">
                    {headers.length > 0 && rows.map((row, rIndex) => {
                        return (
                            <div key={rIndex} className="row" style={{width: rowWidth, height: cellHeight}}>
                                {useRowNumbers && <div onClick={() => {rowNumberClicked(row)}} className="row-number-container">
                                                    <input key={rIndex} className="row-number" value={rIndex+1} readOnly/>
                                                </div>
                                }
                                {headers.map((header, hIndex) => {
                                    const styleClasses = getStyleClasses(row, header);
                                    return header.isDropdownCell 
                                        ?
                                            <div key={"selectdiv" + rIndex + "" + hIndex} style={{width: header.cellWidth, height: cellHeight, border: "solid 1px rgb(131, 130, 130)"}}>
                                                <select key={"select" + rIndex + "" + hIndex} className={styleClasses} 
                                                    value={props.getCellValue(header.value, row)} onClick={() => {cellClicked(row)}}
                                                    onChange={(event) => {updateCellValue(row, header, event.target.value)}} onBlur={(event) => {saveCellChanges(row)}}
                                                >
                                                    {getDropdownOptions(header.value, row)}
                                                </select>
                                            </div>
                                        :
                                            <div key={"inputdiv" + rIndex + "" + hIndex} style={{width: header.cellWidth, height: cellHeight, border: "solid 1px rgb(131, 130, 130)"}}>
                                                <input key={rIndex + "" + hIndex} className={styleClasses}  
                                                    value={props.getCellValue(header.value, row)} onClick={() => {cellClicked(row)}} 
                                                    onChange={(event) => {updateCellValue(row, header, event.target.value)}} 
                                                    readOnly={!header.isEditable} onBlur={(event) => {saveCellChanges(row)}}/>
                                            </div>
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className="data-grid-footer">
                <div className="row-count">Row count: {rowCount}</div>
            </div>
            <ConfirmationDialog title="Delete selected rows!" open={showDeleteRowsDialog} handleClose={setShowDeleteRowsDialog} handleAction={deleteSelectedRows}>
                    Are you sure you wish to delete the selected rows?
            </ConfirmationDialog>
            {loading && <LoadingOverlay />}
        </div>
    );
}

export default DataGrid;