import "./DesktopNavigationBar.css";

const DesktopNavBarItem = (props) => {

    const getClassNames = (tabName) => {
        return props.activeTab === tabName ? "nav-icon-button-active" : "nav-icon-button";
    }

    return (
        <div className="nav-bar-item">
            <div className={getClassNames(props.tabName)} onClick={props.onClick}>
                {props.children}
                <div className="nav-icon-button-text">{props.text}</div>
            </div>
        </div>
    )
}

export default DesktopNavBarItem;