import { HTTP_VERB } from "../Enums/http-enums";
import HttpClient from "./HttpClient/http-client";
import { AxiosRequestConfig } from "axios";
import { API_HOST } from "../api-config";

const baseUrl = API_HOST + "/api/";

class SubscriptionService {
    static instance: SubscriptionService;
    client: typeof HttpClient;

    constructor(client: typeof HttpClient){
        if(!SubscriptionService.instance){
            this.client = client;
            SubscriptionService.instance = this;
            return SubscriptionService.instance;
        }

        this.client = SubscriptionService.instance.client;
        return SubscriptionService.instance;
    }

    async getSubscriptions(productName: string){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.GET,
            baseUrl: baseUrl,
            url: baseUrl + "Subscriptions/GetSubscriptions/" + productName,
            headers: {
                "Content-Type": "application/json"
            },
            data: null
        }

        return (await this.client.request(config));
    }
}

const subscriptionServiceInstance = new SubscriptionService(HttpClient);
Object.freeze(subscriptionServiceInstance);

export default subscriptionServiceInstance;