import { HTTP_VERB } from "../../Enums/http-enums";
import HttpClient from "../HttpClient/http-client";
import { AxiosRequestConfig } from "axios";
import { API_HOST } from "../../api-config";

const baseUrl = API_HOST + "/api/business-manager/";

class ExpenseTypeService {
    static instance: ExpenseTypeService;
    client: typeof HttpClient;

    constructor(client: typeof HttpClient){
        if(!ExpenseTypeService.instance){
            this.client = client;
            ExpenseTypeService.instance = this;
            return ExpenseTypeService.instance;
        }

        this.client = ExpenseTypeService.instance.client;
        return ExpenseTypeService.instance;
    }

    async addItem(data: Object){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.POST,
            baseUrl: baseUrl,
            url: baseUrl + "ExpenseType/AddItem",
            headers: {
                "Content-Type": "application/json"
            },
            data: data
        }

        return (await this.client.request(config));
    }

    async getItems(businessEntityId: string){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.GET,
            baseUrl: baseUrl,
            url: baseUrl + "ExpenseType/GetItems/" + businessEntityId,
            headers: {
                "Content-Type": "application/json"
            },
            data: null
        }

        return (await this.client.request(config));
    }

    async updateItem(data: Object){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.PUT,
            baseUrl: baseUrl,
            url: baseUrl + "ExpenseType/UpdateItem",
            headers: {
                "Content-Type": "application/json"
            },
            data: data
        }

        return (await this.client.request(config));
    }

    async removeItems(data: Object){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.DELETE,
            baseUrl: baseUrl,
            url: baseUrl + "ExpenseType/RemoveItems",
            headers: {
                "Content-Type": "application/json"
            },
            data: data
        }

        return (await this.client.request(config));
    }
}

const expenseTypeServiceInstance = new ExpenseTypeService(HttpClient);
Object.freeze(expenseTypeServiceInstance);

export default expenseTypeServiceInstance;