import { FormControl, InputLabel, Select } from "@mui/material";
import "./SimpleDropdown.css";

const SimpleDropdown = (props) => {

  return (
    <FormControl sx={{ m: 1, minWidth: 120}} size={props.size}>
      <InputLabel>{props.label}</InputLabel>
      <Select
          value={props.defaultValue}
          onChange={props.handleChange}
          label={props.label}
      >
          {props.children}
      </Select>
    </FormControl>
  )
}

export default SimpleDropdown;