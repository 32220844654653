import { Button } from "@mui/material";

const BasicButton = (props) => {
    const disabled = props.disabled ? props.disabled : false;
    let color = "";
    if(props.color === "primary"){
        color = "#FF5733";
    } else if(props.color === "secondary"){
        color = "#0798ae;";
    } else if (props.color === "danger"){
        color = "red";
    }

    return (
        <Button variant={props.variant} disabled={disabled} onClick={props.onClick} size={props.size} color={props.color}>
            {props.text}
        </Button>
    )
}

export default BasicButton;