export const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
]

export const DAYS = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
];

export const validateDateString = (value) => {
    let dateRegex = /^\d{4}\/(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])$/;

    return dateRegex.test(value);
}

export const convertDateStringToDateObject = (dateString) => {
    const splitDate = dateString.split('/');
    const year = parseInt(splitDate[0]);
    const month = parseInt(splitDate[1]) - 1;
    const day = parseInt(splitDate[2]) + 1;
    return new Date(year, month, day);
}

export const convertDateObjectToString = (value: string) => {
    let date = new Date(value);
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = (date.getDate()).toString().padStart(2, '0');
    return date.getFullYear() + "/" + month + "/" + day;
}

export const formatCurrentDateObjectToString = () => {
    let date = new Date();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = (date.getDate()).toString().padStart(2, '0');
    return date.getFullYear() + "/" + month + "/" + day;
}

export const getMonthNumber = (month) => {
    switch(month){
        case "January":
            return 1;
        case "February":
            return 2;
        case "March":
            return 3;
        case "April":
            return 4;
        case "May":
            return 5;
        case "June":
            return 6;
        case "July":
            return 7;
        case "August":
            return 8;
        case "September":
            return 9;
        case "October":
            return 10;
        case "November":
            return 11;
        case "December":
            return 12;
        default:
    }
}

export const getMonthDiff = (d1, d2) => {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}