
class UserContextService {
    static instance: UserContextService;

    constructor(){
        if(!UserContextService.instance){
            UserContextService.instance = this;
            return UserContextService.instance;
        }

        return UserContextService.instance;
    }

    createUserContext(email: string, contextToken: string, accessToken: string, refreshToken: string){
        localStorage.setItem("email", email);
        localStorage.setItem("token", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("contextToken", contextToken);
    }

    setBusinessEntityID(value){
        return localStorage.setItem("businessEntityID", value);
    }

    removeBusinessEntityID(){
        return localStorage.removeItem("businessEntityID");
    }

    getBusinessEntityID(){
        return localStorage.getItem("businessEntityID");
    }

    setBusinessName(value){
        return localStorage.setItem("businessName", value);
    }

    getBusinessName(){
        return localStorage.getItem("businessName");
    }

    removeBusinessName(){
        return localStorage.removeItem("businessName");
    }

    getEmail(){
        return localStorage.getItem("email");
    }

    getAccessToken(){
        return localStorage.getItem("token");
    }

    getRefreshToken(){
        return localStorage.getItem("refreshToken");
    }

    getContextToken(){
        return localStorage.getItem("contextToken");
    }

    getUserContext(){
        return {
            Email: this.getEmail(),
            AccessToken: this.getAccessToken(),
            RefreshToken: this.getRefreshToken(),
            ContextToken: this.getContextToken(),
            BusinessEntityID: this.getBusinessEntityID(),
            BusinessName: this.getBusinessName()
        }
    }

    updateAuthorizationTokens(accessToken: string, refreshToken: string){
        localStorage.setItem("token", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
    }

    clearUserContext(){
        localStorage.removeItem("email");
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("contextToken");
        localStorage.removeItem("businessEntityID");
        localStorage.removeItem("businessName");
    }
}

const userContextServiceInstance = new UserContextService();
Object.freeze(userContextServiceInstance);

export default userContextServiceInstance;