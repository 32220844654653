export const buttonVariants = {
    text: "text",
    contained: "contained",
    outlined: "outlined"
};

export const buttonSizes = {
    small: "small",
    medium: "medium",
    large: "large"
};