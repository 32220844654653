import "./ConfirmationDialog.css";
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmationDialog = (props) => {
    
    const handleClose = () => {
        props.handleClose(false);
    };

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={props.handleAction}>Yes</Button>
            </DialogActions>
      </Dialog>
    );
}

export default ConfirmationDialog;