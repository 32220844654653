import "./ExpenseTypes.css";
import DataGrid from "../DataGrid/DataGrid";
import LoadingOverlay from "../../../Shared/Components/Overlays/LoadingOverlay/LoadingOverlay";
import { useEffect, useState } from "react";
import ErrorDialog from "../../../Shared/Components/Dialogs/ErrorDialog/ErrorDialog";
import { v4 as uuidv4 } from 'uuid';
import userContextServiceInstance from "../../../Shared/Services/user-context-service";
import expenseTypeServiceInstance from "../../../Shared/Services/BusinessManager/expense-type-service";
import categoryItemsServiceInstance from "../../../Shared/Services/BusinessManager/category-items-service";
import { CATEGORY_TYPES } from "../../../Shared/Enums/category-types";

const ExpenseTypes = (props) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [rows, setRows] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [showDeleteValidationError, setShowDeleteValidationError] = useState(false);
    const [validationError, setValidationError] = useState("");
    const [categoryItemRows, setCategoryItemRows] = useState([]);
    const [categoryItemHeaders, setCategoryItemHeaders] = useState([]);
    const [selectedcategoryEntityId, setSelectedCategoryEntityId] = useState("");

    useEffect(() => {
        const getExpenseTypeData = async () => {
            const gridHeaders = [
                {id: 1, value: "Name", displayValue: "Expense Types", cellWidth: 200, validCellValue: validName, isEditable: true},
                {id: 2, value: "ExpenseItemsDisplay", displayValue: "Expense Items", cellWidth: 600, validCellValue: null, isEditable: false},
            ];

            setCategoryItemHeaders([{id: 1, value: "Name", displayValue: "Category Items", cellWidth: 250, validCellValue: validCategoryItem, isEditable: true}]);

            await getGridRowDataFromApi();
            setHeaders(gridHeaders);
            setLoading(false);
        }
        
        getExpenseTypeData().catch(err => {
            console.error(err);
            setLoading(false);
            setError(true);
        })

    }, []);

    const getGridRowDataFromApi = async () => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        var response = await expenseTypeServiceInstance.getItems(businessEntityId);
        if(response.status !== 200){
            setError(true);
            setLoading(false);
            return;
        }

        let items = response.data;

        let gridRows = [];
        items.forEach(item => {
            var expenseItemsDisplay = getExpenseItemsDisplayValue(item.items);
            gridRows.push(
                {
                    id: item.entityID, 
                    rowData: { Name: item.name, ExpenseItemsDisplay: expenseItemsDisplay, ExpenseItems: item.items }, 
                    hasError: false,
                    rowSelected: false,
                    isEmpty: false,
                    hasChanges: false,
                    isComplete: true,
                    isNewRow: false
                }
            );
        });

        gridRows = sortGridRows(gridRows);
        if(gridRows.length > 0){
            var row1 = gridRows[0];
            if(selectedcategoryEntityId !== ""){
                row1 = gridRows.find(row => row.id === selectedcategoryEntityId);
                row1 = row1 === undefined || row1 === null ? gridRows[0] : row1; 
            }

            rowNumberClickedHandler(row1);
        }
        setRows(gridRows);
    }

    const getExpenseItemsDisplayValue = (items) => {
        var text = "";
        items.forEach(item => {
            text += item.name + ", ";
        });

        text = text.substring(0, text.length-2);
        return text;
    }

    const sortGridRows = (rows) => {
        rows.sort((a, b) => {
            let name1 = a.rowData["Name"];
            let name2 = b.rowData["Name"];
            if(name1 === "" || name2 === "") { return 1; }
            return name1.localeCompare(name2);
        });

        return rows;
    }

    const getGridRowCellValue = (headerValue, row) => {
        return row.rowData[headerValue];
    }

    const assignRowValues = (row, header, newValue) => {
        row.rowData[header.value] = newValue;

        return row;
    }

    const getEmptyRow = () => {
        return { 
            id: uuidv4(), 
            rowData: {Name: "", ExpenseItemsDisplay: "", ExpenseItems: null}, 
            hasError: false, 
            rowSelected: false, 
            isEmpty: true,
            hasChanges: false,
            isComplete: true,
            isNewRow: true
        };
    }

    const getEmptyCategoryItemRow = () => {
        return { 
            id: uuidv4(), 
            rowData: {Name: "", ExpenseTypeEntityID: ""}, 
            hasError: false, 
            rowSelected: false, 
            isEmpty: true,
            hasChanges: false,
            isComplete: true,
            isNewRow: true
        };
    }

    const canCommitRow = (row) => {
        return row.rowData["Name"] !== "";
    }

    const validName = (value) => {
        return value !== null && value !== undefined && value.trim() !== "";
    }

    const validCategoryItem = (value) => {
        return value !== null && value !== undefined && value.trim() !== "";
    }

    const saveExpenseTypesChanges = (row) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        let payload = {
            EntityID: row.id,
            BusinessEntityID: businessEntityId,
            Name: row.rowData["Name"]
        };

        setLoading(true);

        if(row.isNewRow){
            expenseTypeServiceInstance.addItem(payload).then((response) => {
                if(response.status !== 200){
                    setError(true);
                    setLoading(false);
                    return;
                }
    
                setLoading(false);
            });
        }
        else {
            expenseTypeServiceInstance.updateItem(payload).then((response) => {
                if(response.status !== 200){
                    setError(true);
                    setLoading(false);
                    return;
                }
    
                setLoading(false);
            });
        }
    }

    const deleteRows = async (guidList) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        let payload = {
            BusinessEntityID: businessEntityId,
            ExpenseTypeEntityIDs: guidList
        };

        setLoading(true);
        var response = await expenseTypeServiceInstance.removeItems(payload);
        if(response.status !== 200){
            setError(true);
            setLoading(false);
            return;
        }

        const data = response.data;
        if(!data.deleteSuccess && data.validationErrors){
            setShowDeleteValidationError(true);
            setValidationError(data.validationErrors[0]);
        }

        setLoading(false);
        await getGridRowDataFromApi();
    }

    const rowNumberClickedHandler = (row) => {
        var items = row.rowData["ExpenseItems"];
        if(row.rowData["Name"] === ""){
            return;
        }
        
        let gridRows = [];
        if(items){
            items.forEach(item => {
                gridRows.push(
                    {
                        id: item.entityID, 
                        rowData: { Name: item.name, ExpenseTypeEntityID: row.id }, 
                        hasError: false,
                        rowSelected: false,
                        isEmpty: false,
                        hasChanges: false,
                        isComplete: true,
                        isNewRow: false
                    }
                );
            });

            gridRows = sortGridRows(gridRows);
        }

        setSelectedCategoryEntityId(row.id);
        setCategoryItemRows(gridRows);
        setCategoryItemHeaders([{id: 1, value: "Name", displayValue: row.rowData["Name"], cellWidth: 250, validCellValue: validCategoryItem, isEditable: true}]);
    }

    const saveCategoryItemChanges = async (row) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        let payload = {
            EntityID: row.id,
            BusinessEntityID: businessEntityId,
            ParentEntityID: row.rowData["ExpenseTypeEntityID"] === "" ? selectedcategoryEntityId : row.rowData["ExpenseTypeEntityID"],
            Name: row.rowData["Name"],
            CategoryType: CATEGORY_TYPES.Expenses
        };

        setLoading(true);

        if(row.isNewRow){
            categoryItemsServiceInstance.addItem(payload).then(async (response) => {
                if(response.status !== 200){
                    setError(true);
                    setLoading(false);
                    return;
                }
    
                setLoading(false);
                await getGridRowDataFromApi();
            });
        }
        else {
            categoryItemsServiceInstance.updateItem(payload).then(async (response) => {
                if(response.status !== 200){
                    setError(true);
                    setLoading(false);
                    return;
                }
    
                setLoading(false);
                await getGridRowDataFromApi();
            });
        }
    }

    const deleteCategoryItemRows = async (guidList) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        let payload = {
            BusinessEntityID: businessEntityId,
            CategoryItemEntityIDs: guidList,
            CategoryType: CATEGORY_TYPES.Expenses
        };

        setLoading(true);
        var response = await categoryItemsServiceInstance.removeItems(payload);
        if(response.status !== 200){
            setError(true);
            setLoading(false);
            return;
        }

        const data = response.data;
        if(!data.deleteSuccess && data.validationErrors){
            setShowDeleteValidationError(true);
            setValidationError(data.validationErrors[0]);
        }

        setLoading(false);
        await getGridRowDataFromApi();
    }

    return (
        <div className="expense-type-container">
            <div className="expense-type-grid-container">
                <DataGrid gridRows={rows} gridHeaders={headers} saveGridChanges={saveExpenseTypesChanges} getEmptyRow={getEmptyRow}
                    getCellValue={getGridRowCellValue} canCommitRow={canCommitRow} deleteRows={deleteRows} sortGridRows={sortGridRows}
                    assignRowValues={assignRowValues} rowNumberClickedHandler={rowNumberClickedHandler} cellClickedHandler={rowNumberClickedHandler}/>
            </div>
            <div className="category-items-grid-container">
                <DataGrid gridRows={categoryItemRows} gridHeaders={categoryItemHeaders} saveGridChanges={saveCategoryItemChanges} 
                    getEmptyRow={getEmptyCategoryItemRow} getCellValue={getGridRowCellValue} canCommitRow={canCommitRow} deleteRows={deleteCategoryItemRows} 
                    sortGridRows={sortGridRows} assignRowValues={assignRowValues}/>
            </div>
            
            <ErrorDialog open={error} handleClose={setError} title="Error">
                An unexpected error occured. Please try again later.
            </ErrorDialog>
            <ErrorDialog open={showDeleteValidationError} handleClose={setShowDeleteValidationError} title="Error">
                {validationError}
            </ErrorDialog>
            {loading && <LoadingOverlay />}
        </div>
    )
}

export default ExpenseTypes;