export const textFieldVariants = {
    outlined: "outlined",
    filled: "filled",
    standard: "standard"
};

export const textFieldSizes = {
    small: "small",
    medium: "medium",
    large: "large"
};