
export const rowIsEmpty = (row, headers) => {
    let emptyRow = true;
    headers.forEach(header => {
        //console.log(header.value + " - " + row.rowData[header.value]);
        if(header.isEditable && String(row.rowData[header.value]) !== ""){
            emptyRow = false;
        }
    });
    
    //console.log("");
    return emptyRow;
}

export const getNumberOfEmptyRows = (rows) => {
    let emptyRowCount = 0;

    rows.forEach(row => {
        if(row.isEmpty){
            emptyRowCount++;
        }
    });

    return emptyRowCount;
}