import { Typography } from "@mui/material";
import "./TermsAndConditions.css";

const TermsAndConditions = () => {
    return (
        <div className="terms-container">
            <Typography variant="h4" align="center" fontStyle="italic" fontWeight="bold">Terms and Conditions</Typography>
            
            <div>
              <strong>Effective Date: </strong> September 20, 2024
            </div>
            <br/>

            <div>
              Welcome to <strong>Tran Tech Solutions</strong> ("Company", "we", "our", "us"). These Terms and Conditions ("Terms") govern your use of our 
              software-as-a-service (SaaS) platform, <strong>Business Manager</strong> ("Service"). By accessing or using our Service, you agree to be bound 
              by these Terms. If you do not agree to these Terms, you may not use our Service.
            </div>
            
            <br/>
            <strong>1. Acceptance of Terms</strong>
            <br/>

            <div>
              By using our Service, you agree to comply with these Terms and all applicable laws and regulations. If you are using the 
              Service on behalf of a company or other entity, you represent that you have the authority to bind that entity and its 
              affiliates to these Terms.
            </div>

            <br/>
            <strong>2. Service Description</strong>
            <br/>

            <div>
              The Business Manager is used to assist businesses with managing their financial records. You can capture your business's 
              incomes, expenses and assets in order to generate an income statement for your business. 
            </div>
            

            <br/>
            <strong>3. User Accounts</strong>
            <br/>
            
            <div>
              To access and use certain features of the Service, you must create an account. You agree to provide accurate, current, 
              and complete information during the registration process and to update such information to keep it accurate, current, and complete.
              <ul>
                <li>
                  <strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account credentials and for all activities 
                  that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
                </li>
                <li>
                  <strong>Account Termination:</strong> We reserve the right to suspend or terminate your account and access to the Service if 
                  you violate these Terms or if we reasonably believe that your use of the Service poses a risk to our business or other users.
                </li>
              </ul>
            </div>

            <br/>
            <strong>4. Subscription and Payment</strong>
            <br/>
            
            <div>
              <ul>
                <li>
                  <strong>Fees:</strong> Payments are in the form of a subscription. A once off payment will be made in order to 
                  gain access to the service for the subscription period. No refunds will be given. The subscription will be 
                  active until the period has expired. Once the period has expired there will be no automatic renewal/payment of 
                  the subscription. In order to continue the service once the subcription has ended, you will need to make payment 
                  for the subscription of your choice. You will require a subscription for each business that you add.
                </li>
                <li>
                  <strong>Billing:</strong> You agree to pay all fees associated with your use of the Service according to 
                  the billing cycle selected. Payments are non-refundable, except as required by law.
                </li>
                <li>
                  <strong>Changes in Fees:</strong> We may modify our fees and billing terms from time to time. Any changes 
                  will be communicated to you and will take effect at the start of your next billing cycle.
                </li>
              </ul>
            </div>

            <br/>
            <strong>5. Use of the Service</strong>
            <br/>
            
            <div>
              <ul>
                <li>
                  <strong>Permitted Use:</strong> You may use the Service solely for lawful purposes and in accordance with these Terms. 
                  You agree not to use the Service for any illegal or unauthorized purposes.
                </li>
                <li>
                  <strong>Restrictions:</strong> You agree not to: (a) copy, modify, or distribute the Service or any part of it; (b) reverse 
                  engineer or attempt to extract the source code of the Service; (c) use the Service to infringe on the intellectual 
                  property rights of others; (d) interfere with or disrupt the Service or servers.
                </li>
              </ul>
            </div>

            <br/>
            <strong>6. Intellectual Property</strong>
            <br/>
            
            <div>
              All intellectual property rights in the Service, including but not limited to trademarks, copyrights, 
              and patents, are owned by us or our licensors. Your use of the Service does not grant you any ownership 
              rights to any intellectual property.
            </div>

            <br/>
            <strong>7. Data Privacy</strong>
            <br/>
            
            <div>
              Your use of the Service is subject to our Privacy Policy, which describes how we collect, use, and protect 
              your personal information. By using the Service, you consent to our <a href="/privacy-policy">Privacy Policy</a>.
            </div>

            <br/>
            <strong>8. Limitation of Liability</strong>
            <br/>
            
            <div>
              To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, 
              or punitive damages, or any loss of profits or data, arising out of or in connection with your use of the Service.
            </div>

            <br/>
            <strong>9. Indemnification</strong>
            <br/>
            
            <div>
              You agree to indemnify and hold us harmless from any claims, losses, liabilities, damages, costs, or 
              expenses (including reasonable attorneys' fees) arising out of or related to your use of the Service, 
              your violation of these Terms, or your infringement of any rights of another party.
            </div>

            <br/>
            <strong>10. Termination</strong>
            <br/>
            
            <div>
              We may terminate or suspend your access to the Service at any time, with or without cause, and with or without 
              notice. Upon termination, you will cease all use of the Service and we may delete your account and all associated data.
            </div>

            <br/>
            <strong>11. Governing Law</strong>
            <br/>
            
            <div>
              These Terms are governed by and construed in accordance with the laws of South Africa. Any 
              disputes arising from or related to these Terms or the Service shall be resolved in the 
              courts of South Africa.
            </div>

            <br/>
            <strong>12. Changes to Terms</strong>
            <br/>
            
            <div>
              We may modify these Terms from time to time. We will notify you of any significant changes by posting 
              the updated Terms on our website. Your continued use of the Service after any changes constitutes your 
              acceptance of the new Terms.
            </div>

            <br/>
            <strong>13. Contact Us</strong>
            <br/>
            
            <div>
              If you have any questions about these Terms, please contact us at:
            </div>
            <br/>

            <div>
              Tran Tech Solutions<br/>
              20 Landskroon street, van Riebeeckshof, Bellville, 7530<br/>
              info@trans-tech-solutions.co.za<br/>
              062 165 0446<br/>
            </div>
        </div>
    );
}

export default TermsAndConditions;