
export const calculateDepreciatedValue = (purchaseValue, rate, numMonths) => {
    const valueAfterYears =  purchaseValue * (Math.pow((1 - (rate/100)), (numMonths/12)));
    const depreciatedValue = purchaseValue - valueAfterYears;
    const roundedValue =  Math.round((depreciatedValue + Number.EPSILON) * 100) / 100;

    return purchaseValue - roundedValue;
}

export const calculateSimpleInterestValue = (purchaseValue, rate, numMonths) => {
    let result = purchaseValue * (1 + (rate/100)*(numMonths/12));
    const roundedValue =  Math.round((result + Number.EPSILON) * 100) / 100;
    return roundedValue;
}

export const calculateCompoundInterestValue = (purchaseValue, rate, numMonths, compoundPeriod) => {
    let decimalRate = (rate/100);
    let result =  purchaseValue * (Math.pow((1 + (decimalRate/compoundPeriod)), (compoundPeriod * numMonths)));
    const roundedValue =  Math.round((result + Number.EPSILON) * 100) / 100;
    return roundedValue;
}