import { HTTP_VERB } from "../../Enums/http-enums";
import HttpClient from "../HttpClient/http-client";
import { AxiosRequestConfig } from "axios";
import { API_HOST } from "../../api-config";

const baseUrl = API_HOST + "/api/business-manager/";

class SummaryService {
    static instance: SummaryService;
    client: typeof HttpClient;

    constructor(client: typeof HttpClient){
        if(!SummaryService.instance){
            this.client = client;
            SummaryService.instance = this;
            return SummaryService.instance;
        }

        this.client = SummaryService.instance.client;
        return SummaryService.instance;
    }

    async getSummary(businessEntityId: String, year: number, summaryType: number){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.GET,
            baseUrl: baseUrl,
            url: baseUrl + `Summary/GetSummary/${businessEntityId}/${year}/${summaryType}`,
            headers: {
                "Content-Type": "application/json"
            },
            data: null
        }

        return (await this.client.request(config));
    }
}

const summaryServiceInstance = new SummaryService(HttpClient);
Object.freeze(summaryServiceInstance);

export default summaryServiceInstance;