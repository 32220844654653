import { MenuItem } from "@mui/material";
import SimpleDropdown from "../../../Shared/Components/Dropdowns/SimpleDropdown";
import "./BusinessSelector.css";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { buttonSizes, buttonVariants } from "../../../Shared/Components/Buttons/ButtonEnums";
import BasicButton from "../../../Shared/Components/Buttons/BasicButton";
import userContextServiceInstance from "../../../Shared/Services/user-context-service";
import businessSubscriptionServiceInstance from "../../../Shared/Services/business-subscription-service";
import LoadingOverlay from "../../../Shared/Components/Overlays/LoadingOverlay/LoadingOverlay";
import ErrorDialog from "../../../Shared/Components/Dialogs/ErrorDialog/ErrorDialog";

const BusinessSelector = (props) => {
    const { businesses } = props;
    const [selectedBusinessID, setSelectedBusinessID] = useState(businesses[0].businessEntityID);
    const [selectedBusinessName, setSelectedBusinessName] = useState(businesses[0].name);
    const [showLoading, setShowLoading] = useState(false);
    const [error, setError] = useState(false);
    const [showNoSubscriptionsFound ,setShowNoSubscriptionsFound] = useState(false);
    const isMobile = useMediaQuery({ query: `(max-width: 1050px)` });
    
    const getDropDownComponent = () => {
        let items = [];

        businesses.forEach(business => {
            items.push(<MenuItem key={business.businessEntityID} value={business.name}>{business.name}</MenuItem>); 
        });

        return (
            <SimpleDropdown label="Business name" defaultValue={selectedBusinessName} handleChange={handleDropdownChange} 
                size="small">
                {items}
            </SimpleDropdown>
        );
    }

    const handleDropdownChange = (event) => {
        var businessId = "";
        businesses.forEach(business => {
            if(business.name === event.target.value){
                businessId = business.businessEntityID;
            }
        });

        setSelectedBusinessName(event.target.value);
        setSelectedBusinessID(businessId);
    }

    const selectBusinessClicked = async () => {
        const contextToken = userContextServiceInstance.getContextToken();

        const subscriptionPayload = { ContextToken: contextToken, BusinessEntityID: selectedBusinessID };
        var bmSubscriptionResponse = await businessSubscriptionServiceInstance.getActiveSubscriptions(subscriptionPayload);
        if(bmSubscriptionResponse.status !== 200){
            setError(true);
            setShowLoading(false);
            props.setShowBusinessSelector(false);
            return;
        }

        userContextServiceInstance.setBusinessEntityID(selectedBusinessID);
        
        const activeSubscriptions = bmSubscriptionResponse.data;
        if(activeSubscriptions.length === 0){
            setShowLoading(false);
            setShowNoSubscriptionsFound(true);
            props.setShowSubscriptions(true);
            props.setShowBusinessSelector(false);
            return;
        }

        userContextServiceInstance.setBusinessName(selectedBusinessName);
        props.setBusinessName(selectedBusinessName);
        props.setShowBusinessManager(true);
        setShowLoading(false);
        props.setShowBusinessSelector(false);
    }

    const addNewBusinessClicked = () => {
        userContextServiceInstance.removeBusinessEntityID();
        userContextServiceInstance.removeBusinessName();
        props.setShowSubscriptions(true);
        props.setShowBusinessSelector(false);
    }

    const styleClasses = isMobile ? "business-selector-content-small" : "business-selector-content-large";

    return (
        <div className="business-selector-container">
            <div className={styleClasses}>
                Select your business
                {getDropDownComponent()}
                <BasicButton variant={buttonVariants.contained} size={buttonSizes.medium} text="Open Business Manager" 
                        onClick={selectBusinessClicked} color="primary" disabled={selectedBusinessID === ""}/>
                <BasicButton variant={buttonVariants.contained} size={buttonSizes.medium} text="Add a business" 
                        onClick={addNewBusinessClicked} color="primary" disabled={selectedBusinessID === ""}/>
            </div>
            {showLoading && <LoadingOverlay />}
            <ErrorDialog open={error} handleClose={setError} title="Error">
                An unexpected error occured. Please try again later.
            </ErrorDialog>
            <ErrorDialog open={showNoSubscriptionsFound} handleClose={setShowNoSubscriptionsFound} title="Error">
                There are no active subscriptions found for {selectedBusinessName}.
            </ErrorDialog>
        </div>
    )
}

export default BusinessSelector;