import { useMediaQuery } from "react-responsive";
import "./UIProvider.css";

const UIProvider = (props) => {
    const isMobile = useMediaQuery({ query: `(max-width: 800px)` });

    const classes = isMobile ? "uiprovider-form-small" : "uiprovider-form-large";
    
    return(
        <div className="uiprovider-container">
            <div className={classes}>
                {props.children}
            </div>
        </div>
    );
}

export default UIProvider;