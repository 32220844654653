import { useMediaQuery } from "react-responsive";
import "./BusinessManagerHeader.css";

const BusinessManagerHeader = (props) => {
    const { businessName } = props;
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    return (
        <div className={isMobile ?  "business-manager-header-container-small" : "business-manager-header-container-large"}>
            <div className={isMobile ? "bmh-title-small" : "bmh-title-large"}>
                {businessName} Business Manager
            </div>
        </div>
    );
}

export default BusinessManagerHeader;