
export const validDecimalNumber = (value) => {
    if(value && value.length > 1){
        if(value.includes(".")){
            var splitValue = value.split(".");
            if(splitValue[0].length > 1 && splitValue[0][0] === "0"){
                return false;
            }

            if(splitValue[1].length > 2){
                return false;
            }
        }
        else if(value[0] === "0"){
            return false; 
        }
    }

    const decimalRegex = /^\d+\.?\d*$/;
    return decimalRegex.test(value);
}

export const validateIntegerNumber = (value) => {
    if(value && value.length > 1){
        if(value[0] === "0"){
            return false; 
        }
    }

    const numberRegex = /^\d+$/;
    return numberRegex.test(value);
}

export const formatNumber = (value) => {
    var parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
}