import { useEffect, useRef } from "react";
import "./HeaderMenu.css";
import HeaderMenuItem from "./HeaderMenuItem";
import { useNavigate } from 'react-router-dom';
import userContextServiceInstance from "../../Shared/Services/user-context-service";

const HeaderMenu = (props) => {
    let navigate = useNavigate();
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    //Hook that alerts clicks outside of the passed ref
    function useOutsideAlerter(ref) {
        useEffect(() => {
        //Alert if clicked on outside of element
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                props.closeMenu(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        }, [ref]);
    }

    const logoutClicked = () => {
        console.log("Logout clicked");
        navigate("/");
        userContextServiceInstance.clearUserContext();
        props.setLogggedIn(false);
        props.closeMenu(false);
    }

    const navigateToRoute = (routeName) => {
        navigate(routeName);
        props.closeMenu(false);
    }

    return (
        <div ref={wrapperRef} className="header-menu-container">
            <HeaderMenuItem name="Home" onClick={() => navigateToRoute("/")}/>
            <HeaderMenuItem name="My Business Manager" onClick={() => navigateToRoute("/business-manager")}/>
            {/* <HeaderMenuItem name="Apps and Games" onClick={() => navigateToRoute("/apps-games")}/> */}
            <HeaderMenuItem name="My Profile" onClick={() => navigateToRoute("/my-profile")}/>
            <HeaderMenuItem name="Logout" onClick={logoutClicked}/>
        </div>
    );
}

export default HeaderMenu;