import { HTTP_VERB } from "../Enums/http-enums";
import HttpClient from "./HttpClient/http-client";
import { AxiosRequestConfig } from "axios";
import { API_HOST } from "../api-config";

const baseUrl = API_HOST + "/api/";

class RecaptchaService {
    static instance: RecaptchaService;
    client: typeof HttpClient;

    constructor(client: typeof HttpClient){
        if(!RecaptchaService.instance){
            this.client = client;
            RecaptchaService.instance = this;
            return RecaptchaService.instance;
        }

        this.client = RecaptchaService.instance.client;
        return RecaptchaService.instance;
    }

    async verifyRecaptchaCode(captchaCode){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.POST,
            baseUrl: baseUrl,
            url: baseUrl + "recaptcha/verifycode/",
            headers: {
                "Content-Type": "application/json"
            },
            data: { CaptchaCode: captchaCode }
        }

        return (await this.client.request(config)).data;
    }
}

const RecaptchaServiceInstance = new RecaptchaService(HttpClient);
Object.freeze(RecaptchaServiceInstance);

export default RecaptchaServiceInstance;